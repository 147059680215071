<template>
    <div class="page-background">
        <div class="main-big-text">
            <p>One-To-All</p>
            <p>Connectivity Platform</p>
            <p>Cereb is a layman-friendly configurations software to enable commercial-scale Internet-of-things (IoT) interoperability and automations.</p>
        </div>
        <div class="signup-box">
            <!-- <div class="signup-box-img-box">
                <img class="signup-box-img-box-img" src="../assets/login-page-big-img.png">
            </div> -->

            <div class="signup-box-signup">
                <div class="logo-app_name-box">
                    <img class="signup-box-signup-logo" src="../assets/logo.png">
                    <div class="signup-box-signup-app-name">Cereb Platform</div>
                </div>

                <div class="signup_text-to-login_hyperlink-box">
                    <div class="signup-text">Sign up</div>
                
                    <div class="signup-form-username-text">Username：</div>
                    <input class="login-form-username-input" type="text" v-model="username" name="username" />

                    <div class="signup-form-email-text">Email：</div>
                    <input class="signup-form-email-input" type="text" v-model="email" name="eamil" />

                    <div class="signup-form-password-text">Password：</div>
                    <input class="signup-form-password-input" type="password" v-model="password" name="password" />

                    <div class="signup-form-confirm-password-text">Confirm Password：</div>
                    <input class="signup-form-confirm-password-input" type="password" v-model="confirmPassword" name="confirm-password" />   
					
					<div class="message-panel">
						<!-- <span class="icon-error">Username already exists</span> -->
					</div>

                    <el-button class="confirm-button" :loading="loginButtonLoading" type="button" @click="submitClick">
                        <span class="confirm-text">
                            Sign Up
                        </span>
                    </el-button>

    
                    <div class="log-in-box">
                        <a href="#">
                            <span class="log-in" @click="loginClick">
                                Log in
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {signup} from '../api'


export default {
    name: 'Signup',

    data : function() {
        return {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            loginButtonLoading: false,
        }
    },

    props: {
        msg: String
    },
    methods: {
        loginClick() {
            this.$router.push({'name' : 'login'})
        },
		submitClick : function() {
            if(this.username && this.email && this.password && this.confirmPassword){
                if(this.password === this.confirmPassword){
                    this.loginButtonLoading = true;
                    // signup(this.username, this.email, this.password).then((token) => {
                    signup(this.username, this.email, this.password).then(() => {
                        // localStorage.token = token
                        this.$router.push({'name' : 'login'})
                    }).catch((err) => {
                        console.log(err)
                        this.$message.error({center: true, message: 'Duplicate username or email, please re-enter！'})
                    }).finally(() => {
                        this.loginButtonLoading = false;
                })
                } else{
                    this.$message.error({center: true, message: 'Password input is inconsistent, please re-enter！'})
                }
            } else{
                this.$message.error({center: true, message: 'Please enter the complete information！'})
            }
		}
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
    /* Signup Page */
    .signup-box {transform: translate(-50%, -50%);}
    .signup-box input {padding:0px 10px;}
    .signup-box input.login-form-username-input,
    .signup-box input.signup-form-email-input,
    .signup-box input.signup-form-password-input,
    .signup-box input.signup-form-confirm-password-input {width:289px;}
    .signup-box .message-panel {padding:10px 0px 5px; color:#FFFFFF; font-size:14px;}
    /* Signup Page */


    .page-background {
        box-sizing: content-box;
        position: absolute;
        width: 100%;
        height: 100%;
        /* background: linear-gradient(360deg, #212A3A 0%, #182130 100%); */
        background-image: url(../assets/page-background-img.png);
        position:fixed;
        top: 0;
        left: 0;    
        width:100%;
        height:100%;
        min-width: 1000px;
        zoom: 1;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center 0;  
        background-size:100% 100%;

    }


    .signup-box {
        position: absolute;
        /*margin-left: -460px;
        margin-top: -290px;*/
        left: 75%;
        top: 50%;
        width: 418px;
        min-height: 580px;
        /* background: #193B92; */
        border-radius: 2px;
    }


    /* .signup-box-img-box {
        z-index: 0; */
        /*position: absolute;*/
		/* position: relative;
		float:left;
        width: 542px;
        min-height: 580px;
    }
    .signup-box-img-box-img {
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -231px;
        margin-top: -231px;
        max-width: 462px;
        max-height: 462px;
    } */

    .signup-box-signup {
        /*position: absolute;*/
		position: relative;
		float:left;
        right: 0%;
        bottom: 0%;
        width: 378px;
        min-height: 580px;
        background: #FEFEFE;
        border-radius: 2px;
    }

    

    .logo-app_name-box {
        position: absolute;
        margin-left: 34px;
        margin-top: 37px;
        margin-right: 34px;
        margin-bottom: 499px;
        width: 310px;
        height: 44px;
    }

    .signup-box-signup-logo {
        position: absolute;
        left: 55px;
        top: 0px;
        width: 44px;
        height: 44px;
    }

    .signup-box-signup-app-name {
        position: absolute;
        margin-left: 105px;
        margin-top: 8px;
        margin-bottom: 10px;

        width: 142px;
        height: 26px;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0B0E14;
        line-height: 26px;
    }





    .signup_text-to-login_hyperlink-box {
        /*position: absolute;
        margin-left: 34px;
        margin-top: 108px;
        margin-right: 34px;
        margin-bottom: 14px;*/
		padding-left: 34px;
        padding-top: 108px;
        padding-right: 34px;
        padding-bottom: 22px;
        width: 310px;
        min-height: 458px;
    }


    .signup-text {
        width: 266px;
        height: 28px;
        font-size: 19px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    .signup-form-username-text {
        margin-top: 23px;
        width: 144px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }

    .login-form-username-input {
        margin-top: 12px;
        border-radius: 4px;
    }


    .signup-form-email-text {
        margin-top: 13px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }

    .signup-form-email-input {
        margin-top: 12px;
        border-radius: 4px;
    }


    .signup-form-password-text {
        margin-top: 13px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }
    input {
        /* background: #182130;  设置输入框背景色 */
        padding: 0px 0px;
        width: 309px;
        height: 39px;
        border: 1px solid #E2E2E2;
        /* color: #FFFFFF; */
        font-size: 14px;
    }


    .signup-form-password-input {
        margin-top: 12px;
        border-radius: 4px;
    }

    a {
        text-decoration: none;
    }


    .signup-form-confirm-password-text {
        margin-top: 13px;
        width: 144px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }

    .signup-form-confirm-password-input {
        margin-top: 12px;
        border-radius: 4px;
    }

    .confirm-button {
        margin-top: 19px;
        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    .confirm-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
    }

    .log-in-box {
        margin-top: 14px;
        width: 310px;
        height: 20px;
        text-align: center;
    }

    .log-in {
        width: 52px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    body {
        display: block;
        margin: 0px;
    }
</style>
<style scoped>
.page-background .main-big-text{
    float: left;
    /* width: 500px; */
    height: 400px;
    margin-top: 12%;
    margin-left: 12%;
}
.page-background .main-big-text p{
    padding: 0;
    margin: 25px 0 0 0;
}
.page-background .main-big-text p:nth-child(1){
    font-size: 43px;
    color: #FCB900;
    font-family: HarmonyOS_Sans_Black;
}
.page-background .main-big-text p:nth-child(2){
    font-size: 43px;
    color: #FEFEFE;
    font-family: HarmonyOS_Sans_Black;
}
.page-background .main-big-text p:nth-child(3){
    font-size: 14px;
    font-family: HarmonyOS_Sans;
    color: #FFFFFF;
    width: 419px;
    opacity: 0.8;
}
</style>